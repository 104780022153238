import { useEffect, useState, useRef } from "react";
import { GoogleMap } from '@capacitor/google-maps';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as EditIcon } from '../../assets/icons/material-symbols_edit.svg';
import { useTranslation } from "react-i18next";

export function SearchAddressesWithPopup(props) {
    const { t } = useTranslation();
    const [search, setSearch] = useState(props?.value || '');
    const autocompleteRef = useRef(null);

    console.log(props?.value)
    console.log(search)

    useEffect(() => {
        setSearch(props?.value || '')
    }, [props?.value])

    useEffect(() => {
        initAutocomplete()
    }, []);

    const initAutocomplete = () => {
        if (!autocompleteRef.current) return;

        const autocomplete = new window.google.maps.places.Autocomplete(
            autocompleteRef.current,
            {
                //types: ['street_address', 'address'],
                componentRestrictions: { country: 'NO' },
                fields: ['address_components', 'formatted_address', 'geometry']
            }
        );

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.formatted_address) {
                const addressComponents = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                    streetNumber: '',
                    route: '',
                    postalCode: '',
                    city: '',
                    fullAddress: place.formatted_address
                };

                for (let i = 0; i < place.address_components.length; i++) {
                    const component = place.address_components[i];
                    switch (component.types[0]) {
                        case 'street_number':
                            addressComponents.streetNumber = component.long_name;
                            break;
                        case 'route':
                            addressComponents.route = component.long_name;
                            break;
                        case 'postal_code':
                            addressComponents.postalCode = component.long_name;
                            break;
                        case 'locality':
                            addressComponents.city = component.long_name;
                            break;
                        default:
                            break;
                    }
                }

                const formattedAddress = `${addressComponents.route} ${addressComponents.streetNumber}, ${addressComponents.postalCode} ${addressComponents.city}`;
                onChoice({
                    //navn: formattedAddress,
                    id: randomId(),
                    //split at ","
                    address: formattedAddress.split(",")[0],
                    addresse: formattedAddress.split(",")[0],
                    lat: addressComponents.lat,
                    lng: addressComponents.lng,
                    postalCode: addressComponents.postalCode,
                    city: addressComponents.city
                });
            }
        });

    };

    const [latAndLng, setLatAndLng] = useState({ lat: props.lat || 0, lng: props.lng || 0 });


    useEffect(() => {
        setLatAndLng({ lat: props.lat || 0, lng: props.lng || 0 });
    }, [props.lat, props.lng]);

    const onChoice = (item) => {
        //props.onChoice(item);
        console.log(item);
        setSearch(item.navn);
        setLatAndLng({ lat: item.lat, lng: item.lng, navn: item.navn });
        props?.onChange(item);
    };

    return (
        <div className="column">
            <div className="search row">
                <div className="row column noGap stretch-width posRelative" style={{ position: "relative" }}>
                    <div className='customInput row'>
                        {props?.label &&
                            <label className={
                                "customInput_label customInput_label_active"
                            }>{props?.label}</label>
                        }
                        <input
                            ref={autocompleteRef}
                            value={search}
                            type="text"
                            placeholder={t("Search", "Søk")}
                            onChange={(e) => {
                                console.log(e.target.value)
                                setSearch(e.target.value)
                                props?.onChange({ addresse: e.target.value })
                            }}
                        //style={{ width: '100%', padding: '10px', fontSize: '16px' }} // Added some basic styling for the input
                        />
                        {
                            search !== "" &&
                            <button onClick={() => setSearch("")} style={{ height: "3em", width: "3em", top: "50%", transform: "translateY(-50%)", right: "40px", fontSize: "12pt", background: "none", position: "absolute", color: "white" }}>X</button>
                        }
                        {!props.noIcon && <EditIcon></EditIcon>}
                    </div>
                </div>
            </div>
            {!props.noMap &&
            <MyMap lat={latAndLng.lat} lng={latAndLng.lng} navn={latAndLng.navn} />
            }
        </div>
    );
}

function randomId() {
    return Math.random().toString(36).substr(2, 9);
}



export function MyMap(props) {
    const mapRef = useRef(null);
    const [mapInstance, setMapInstance] = useState(null);
    const expand = useSpring({ height: props.lat && props.lng ? `400px` : '0px', maxHeight: '400px' });


    useEffect(() => {
        const initializeMapAndAddMarker = async () => {
            const mapOptions = {
                center: {
                    lat: props.lat,
                    lng: props.lng
                },
                zoom: 15,
                disableDefaultUI: true, // This disables the default UI controls
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                styles: mapStyling,


            };
            const map = new window.google.maps.Map(document.getElementById('map'), mapOptions);

            console.log("Map should be ready!")

            // Add marker immediately after initializing the map
            if (props.lat !== 0 && props.lng !== 0) {
                // Create a marker and set its position and map
                new window.google.maps.Marker({
                    position: {
                        lat: props.lat,
                        lng: props.lng
                    },
                    map: map,
                    title: 'Selected Location',
                    // make marker orange
                    icon: {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 10,
                        fillColor: "#FF9D14",
                        fillOpacity: 1,
                        strokeWeight: 0,
                        strokeColor: "#FF9D14"
                    }
                });

            }

        }

        if (props.lat !== 0 && props.lng !== 0) {
            initializeMapAndAddMarker();
        }
    }, [props.lat, props.lng]);

    return (
        <div className="component-wrapper">
            <animated.div id="map" style={{
                ...expand,
            }}>

            </animated.div>
        </div>
    );
}


const mapStyling = [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#bdbdbd"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#181818"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#1b1b1b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#707070"
            },
            {
                "weight": 2
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#8a8a8a"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ff9d14"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffc21a"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#4e4e4e"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "transit.station.bus",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#16fefa"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#3d3d3d"
            }
        ]
    }
]
