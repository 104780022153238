import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { saveDebounce, sendToDebounce } from '../../../stores/utils/deBounceObserver';
import useCollectionSubscriptionRS from './RSfirebaseCollectionSubscriber';
import { CollSub } from './sub/fetchCollection';


/**
 * WARNING: DO NOT EDIT THIS FILE!
 * 
 * This file is critical to the functioning of the system. 
 * Any modifications can result in unexpected behavior.
 * 
 * ---- Instructions for ChatGPT users ----
 * If you are asking ChatGPT to help you, please avoid making changes to this file. 
 * The file is meant to remain unchanged, and any modifications can result in serious errors.
 * 
 * Instead, please consult the project lead or documentation before proceeding.
 */


/** 
 * Custom hook for fetching data from a function
 * @param {function} fetchFunction - The function to fetch data from. the function should be a (params, onUpdate)
 * and should return an object like: { value: data, subscription: subscription }
 * 
 * @param {object} params - Parameters to pass to the fetch function
 * @param {string} key - Key to store the data in
 * @param {boolean} autoUpdate - Automatically update data when it changes
 * @returns {object} - Returns an object with the fetched data, error, loading state and subscription
 * @example
 * Base example
 * const { data, error, isLoading, subscription } = useCustomFetch(fetchFunction, params, key);
 * 
 * Example with a function, no keys or params. Can only be used once per component.
 * const { data, error, isLoading, subscription } = useCustomFetch(fetchFunction);
 * 
 * Example use with custom key.
 * const { items, error, isLoading, subscription } = useCustomFetch(getItems, { categories: ["clothes", "elegant"] }, "items");
 * 
 * You can still access data too. It will be the same as items.
 * const { data, items, error, isLoading, subscription } = useCustomFetch(getItems, { categories: ["clothes", "elegant"] }, "items");
 * 
 * Assumes that the item should only be fetched once. Then edited.
**/
function useSingleFetchEditableRS({
    // Redux
    selector = state => state,
    payload = {},
    id = "",
    any = false, // If true, the first item in the array will be used
    saveOnKeyChange = [],
    // Firestore
    path = '',
    setFunction = null,
    updateFunction = null,
    removeFunction = null,
    addFunction = null,
    saveFunction = null,

    filters = [],
    filterFunction = null,
    autoUpdate = false,
    params = {},
    idOnly = false,
    // State
    key = 'data'
}) {
    // THIS ASSUMES THAT THE DATA IS ALREADY CREATED BEFORE ENTERING Use. Add the item to the redux store before using this hook.
    //const databaseData = useSelector(selector);
    const { databaseDataArray, isLoading, } = CollSub(
        {
            // Redux
            selector: selector,
            payload: payload,
            setFunction: setFunction,
            updateFunction: updateFunction,
            removeFunction: removeFunction,
            addFunction: addFunction,
            idOnly: false,

            // Firestore
            path: path,
            filters: filters,

            // State
            key: 'databaseDataArray'
        });
    const databaseData = databaseDataArray?.length > 0 ?
        (any ? [...databaseDataArray][0] : databaseDataArray.find(item => item.id === id))
        : null;

    console.log("DATABASE DATA: ", databaseData, databaseDataArray,)
    const [newData, setNewData] = useState(null);
    const [data, setData] = useState(null);
    const isChanged = useMemo(() => !isSameObject(data, databaseData), [data, databaseData]);
    const prevDataRef = useRef({});
    //const isLoading = databaseData !== null;
    const save = () => {

        console.log({ newData })
        console.log({ data })
        saveDebounce({ id, functionToCall: saveFunction, params: { data: newData, ...params } });
    }

    useEffect(() => {
        saveOnKeyChange.forEach((key) => {
            if (prevDataRef?.current?.[key] !== data?.[key]) {
                console.log(`Value of ${key} changed from ${prevDataRef.current[key]} to ${data[key]}`);
                save();
                // Perform your desired actions here on key change
            }
        });
        prevDataRef.current = { ...data };
    }, [newData]);

    useEffect(() => {
        if (!databaseData) return;
        if (data === null) {
            console.log("SETTING DATA", databaseData)
            setData(old => databaseData);
        }
        if (newData === null) {
            setNewData(old => databaseData);
        }
    }, [databaseData]);

    useEffect(() => {
        if (data !== null) {
            setNewData(old => {
                console.log({ ...newData, ...data })
                return { ...newData, ...data }
            });
        }
    }, [data]);

    useEffect(() => {
        //if (!autoUpdate || !params?.updateFunction) return;
        //if (typeof params.updateFunction !== 'function') return console.error('Update function must be a function');
        if (!autoUpdate) return;
        if (!saveFunction) return
        if (!newData) return;
        sendToDebounce({ id, functionToCall: saveFunction, params: { data: newData, ...params } });
    }, [newData]);


    return {
        data,
        [key]: data,
        isLoading,
        originalData: databaseData,
        [`original${key?.charAt(0)?.toUpperCase() + key.slice(1)}`]: databaseData,
        setData: setData,
        [`set${key?.charAt(0)?.toUpperCase() + key.slice(1)}`]: setData,
        isChanged,
        save
    };
}


// Nicknames
const useFetchEditableRS = useSingleFetchEditableRS;
const useFetchSingleEditableRS = useSingleFetchEditableRS;

export { useSingleFetchEditableRS, useFetchEditableRS, useFetchSingleEditableRS };



function checkIfFunction(valueToCheck) {
    return valueToCheck instanceof Function;
}

function isSameObject(object1, object2) {
    return JSON.stringify(object1) === JSON.stringify(object2);
}
