import { Autocomplete, Button, Dialog, DialogContent, TextField } from "@mui/material";
import DefaultWrapper from "../../components/defaultWrapper";
import TimeChooser from "../../components/timetracking/timeChooser";
import { checkForTimeOverlap, createId, deleteTimeFromDB, getFirmSettings, gjennomførtTimeIgnoreOverlap, uploadTimeToDb } from "../../firebase";
import CustomDropdown from "../../components/miniComponents/customDropdown";
import CustomInput from "../../components/miniComponents/customInput";

import React, { useState, useEffect, useContext, useRef, forwardRef } from "react";

import { getCompanyTimeSettings, getClientsAndProjectsInOne } from "../../firebase";
import { createDateOutOfFirebaseTimestamp, CustomPaper, customSxStyles } from "./timeføring";
import { AlertContext, CheckIfOnPC, UserContext } from "../../App";
import { useHistory } from "react-router";

import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import BackgroundImage from '../../assets/Background.png';
import UtstyrSearchWithPopup from "../../components/searchWithPopups/utstyrSearch";
import { useTranslation } from "react-i18next";
import CircleIconButton from "../../components/miniComponents/circleIconButton";
import { Add, Delete, FlightTakeoffRounded } from "@mui/icons-material";
import ExtraTime from "./extraTimes";
import moment from 'moment-timezone';
import ButtonListCheckbox from "../../components/miniComponents/buttonListCheckbox";
import CheckboxInput from "../../components/miniComponents/checkbox-input";
import TimetypeModalButton from "../../components/modals/timetypeModalButton";
import { useSelector } from "react-redux";




const getCurrentDatePlus10Seconds = () => {
    const date = new Date();
    date.setSeconds(date.getSeconds() + 1);
    return date;
}

export const NewTimePage = () => {
    const { t } = useTranslation();
    const user = useSelector(state => state.user.user);
    const companyUser = useSelector(state => state.user.companyUser);
    const history = useHistory();

    const [timeToEdit, setTimeToEdit] = useState(
        Object.assign(
            {
                id: createId(),
                startDate: roundDownToNearestHour(new Date()),
                endDate: roundDownToNearestHour(getCurrentDatePlus10Seconds()),
                title: "",
                pause: null,
                tilleg: [{
                    id: "test",
                    name: "Normal",
                    prosent: 0,
                }],
                stoppet: true,
                kunde: null,
                prosjekt: null,
                bruker: companyUser,
                kommentar: "",
                aktivitet: null,
                underAktivitet: null,
                extraTimes: [],
                diett: false,
            }, history.location.state?.timeToEdit,
            history.location.state?.timeToEdit &&
            {
                startDate: createDateOutOfFirebaseTimestamp(history.location.state?.timeToEdit?.startDate),
                endDate: createDateOutOfFirebaseTimestamp(history.location.state?.timeToEdit?.endDate),
                dato: createDateOutOfFirebaseTimestamp(history.location.state?.timeToEdit?.dato),
            }
        ))

    console.log(timeToEdit)
    const [settings, setSettings] = useState({
        aktiviteter: [],
        komponenter: {
            utstyr: false,
        },
    })

    const [kunderWithProjects, setKunderWithProjects] = useState([])
    const [tillegOptions, setTillegOptions] = useState([])
    const [pauseDialogOpen, setPauseDialogOpen] = useState(false);
    const { setAlertContext, setOnConfirm } = useContext(AlertContext)

    //refs
    const fraRef = useRef(null)
    const tilRef = useRef(null)

    const isUserInvolvedInActivity = (aktivitet, userId, roles) => {
        const isUserDirectlyInvolved = aktivitet?.users?.some(user => user.id === userId)
        const isAdmin = roles?.includes("admin");
        const hasRequiredRole = roles?.some(role => aktivitet.roles?.includes(role));

        return isUserDirectlyInvolved || isAdmin || hasRequiredRole;
    };

    const onConfirm = async () => {
        console.log("Ran onConfirm")
        await gjennomførtTimeIgnoreOverlap(timeToEdit, user)
        setTimeToEdit((old) => { return { ...old, id: createId() } })
        // go back with history state as time id
        console.log(timeToEdit)

        history.push({
            pathname: "/timeføring",
            state: timeToEdit
        })
        //history.goBack(time.id)
    }


    const getSettings = async () => {
        const onUpdate = (data) => {
            const roles = companyUser.roleIds;
            const userId = companyUser.id;
            const aktiviteter = data?.aktiviteter || [];
            console.log(aktiviteter)

            const aktiviteterForUser = aktiviteter.filter(aktivitet =>
                isUserInvolvedInActivity(aktivitet, userId, roles)
            );

            const newData = { ...data, aktiviteter: aktiviteterForUser };


            setSettings({ ...settings, ...newData })
        }

        const fetchedSettings = await getFirmSettings("timeforingsInstillinger", onUpdate);
        console.log(settings);
        if (settings) setSettings({ ...settings, ...fetchedSettings });
    }

    useEffect(() => {
        getSettings();
    }, [])


    const flattenedOptions = kunderWithProjects.reduce((acc, client) => {
        client.projects.forEach(project => {
            acc.push({ ...project, clientName: client.navn, clientId: client.id });
        });
        return acc;
    }, []);

    const flattenedAktiviteter = settings.aktiviteter.reduce((acc, aktivitet, aktivitetIndex) => {
        aktivitet.underAktiviteter.forEach((underAktivitet, underAktivitetIndex) => {
            // Generate a unique ID. This uses aktivitet's id and indexes as fallbacks
            const uniqueId = underAktivitet.id ?
                `${aktivitet.id}-${underAktivitet.id}` :
                `aktivitet-${aktivitetIndex}-underAktivitet-${underAktivitetIndex}`;

            // Add underAktivitet to the accumulator with additional properties
            acc.push({
                ...underAktivitet,
                aktivitetName: aktivitet.navn,
                aktivitetId: aktivitet.id,
                uniqueId: uniqueId // Use the generated unique identifier
            });
        });
        return acc;
    }, []);



    useEffect(() => {

        setTimeToEdit((old) => { return { ...old, bruker: companyUser } })
    }, [user])

    useEffect(() => {
        console.log(timeToEdit)
        console.log(history.location.state)
        const getClientsWithP = async () => {
            const kunderWithProjects = await getClientsAndProjectsInOne()
            setKunderWithProjects(kunderWithProjects)
        }
        getClientsWithP()
    }, [])

    useEffect(() => {
        async function getTilleg() {
            const tilleg = await getCompanyTimeSettings().then(data => data?.tillegg)
            console.log(tilleg);
            if (!tilleg) return
            console.log(tilleg)

            if (timeToEdit?.tilleg[0]?.id === "test") setTimeToEdit((old) => { return { ...old, tilleg: [tilleg[0]] } })
            setTillegOptions(tilleg)
        }
        getTilleg()
    }, [])

    useEffect(() => {
        // Destructure startDate and endDate from timeToEdit for clarity and to use in dependency array
        const { startDate, endDate } = timeToEdit;
        const startDateTime = new Date(startDate);
        const endDateTime = new Date(endDate);

        // First, ensure endDateTime is at least after startDateTime; if not, adjust to be the next day.
        if (startDateTime && endDateTime) {
            let newEndDateTime = endDateTime;

            if (endDateTime <= startDateTime) {
                newEndDateTime = new Date(startDateTime.getTime() + (24 * 60 * 60 * 1000)); // Add 24 hours to startDateTime
            } else {
                // Calculate time difference using your function
                const timeDifference = calculateTimeDifference(startDateTime, endDateTime);

                // If the difference is greater than 24 hours, adjust endDateTime
                if (timeDifference.hours >= 24 || (timeDifference.hours === 23 && timeDifference.minutes > 0)) {
                    newEndDateTime = new Date(startDateTime.getTime() + (24 * 60 * 60 * 1000)); // Set endDateTime to 24 hours after startDateTime
                }
            }

            // Only update if necessary to prevent infinite loop
            if (newEndDateTime.getTime() !== endDateTime.getTime()) {
                setTimeToEdit(prev => ({
                    ...prev,
                    endDate: newEndDateTime
                }));
            }
        }
    }, [timeToEdit.startDate, timeToEdit.endDate]); // Now directly dependent on startDate and endDate only





    const calculateTimeDifference = (start, end) => {
        const difference = end.getTime() - start.getTime();
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        return { hours, minutes };
    }

    // Function to compute total hours and minutes, considering the pause
    const calculateTotalTime = () => {
        const diff = calculateTimeDifference(timeToEdit.startDate, timeToEdit.endDate);

        // Convert main duration to total minutes
        let totalMinutes = diff.hours * 60 + diff.minutes;

        // Subtract pause time, converted to minutes
        if (timeToEdit.pause) {
            totalMinutes -= (timeToEdit.pause.hours || 0) * 60;
            totalMinutes -= (timeToEdit.pause.minutes || 0);
        }

        // Add extra times, converted to minutes
        if (timeToEdit.extraTimes) {
            totalMinutes += timeToEdit.extraTimes.reduce((acc, time) => {
                let timer = time.to - time.from; // Time difference in milliseconds
                let minutes = timer / 1000 / 60; // Convert milliseconds to minutes
                return acc + minutes;
            }, 0);
        }

        // Calculate total hours and minutes from totalMinutes
        let totalHours = Math.floor(totalMinutes / 60);
        totalMinutes = totalMinutes % 60;

        console.log(totalHours, totalMinutes);
        return { totalHours, totalMinutes };
    };



    // Call the function
    const { totalHours, totalMinutes } = calculateTotalTime();

    // Construct totalTime
    const totalTime = `${totalHours < 10 ? '0' : ''}${totalHours}:${totalMinutes < 10 ? '0' : ''}${totalMinutes}`;

    const idToNameMapping = settings.aktiviteter.reduce((acc, aktivitet) => {
        acc[aktivitet.id] = aktivitet.navn;
        return acc;
    }, {});



    return (
        <DefaultWrapper extraButton={<button className="btn btn-primary" onClick={async (e) => {
            const overlap = await checkForTimeOverlap(timeToEdit, user)
            if (overlap) {
                setAlertContext({
                    text: t("You have already registered an hour", "Du har allerede en time oppføring som overlapper med denne timen, vil du overskrive den?"),
                    onConfirm: onConfirm
                })
            } else {
                console.log("no overlap")
                onConfirm()
                //history.goBack()
                history.push({
                    pathname: "/timeføring",
                    state: timeToEdit
                })
            }
        }}/*onClick={async () => {
            //setTimeChoiseModalInfo({ ...timeChoiseModalInfo, ...newTime, open: false, })
            // MAKE SURE TO CHECK IF ALL FIELDS ARE GOOD
            await uploadTimeToDb(timeToEdit)
            history.goBack()

        }}*/>{t("Done", "Ferdig")}</button>}>
            <div className="content-ny">

                <Autocomplete
                    id="grouped-demo"
                    PaperComponent={CustomPaper}
                    options={flattenedOptions}
                    groupBy={(option) => option.clientName}
                    getOptionLabel={(option) => option?.nummer ? option?.nummer + " - " + option.navn : option.navn}
                    style={{ backgroundColor: "var(--input-background)", borderRadius: 5, }}
                    sx={customSxStyles}
                    renderInput={(params) => <TextField {...params} label="Prosjekt" />}
                    renderGroup={(params) => (
                        <div key={params?.key || params?.id} style={{ background: "var(--input-background)" }}>
                            <p className="orange" style={{ marginLeft: 10 }}>{params.group}</p>
                            <p className="stretch-width" style={{ background: "var(--input-background)", padding: "10px 5px", listStyle: 'none', margin: 0 }}>
                                {params.children}
                            </p>
                        </div>
                    )}
                    filterOptions={(options, { inputValue }) => {
                        try {
                            let results = [];
                            let lowerCaseInputValue = inputValue.toLowerCase(); // Convert input value to lowercase for case-insensitive comparison

                            options.forEach(option => {
                                console.log(option)
                                // Convert clientName and navn to lowercase and check if they include the lowercase input value
                                if (option && option.clientName && option.clientName.toLowerCase().includes(lowerCaseInputValue)) {
                                    results.push(option);
                                }

                                else if (option && option.navn && (option.navn.toLowerCase().includes(lowerCaseInputValue) || option?.nummer?.toLowerCase().includes(lowerCaseInputValue))) {
                                    results.push(option);
                                }
                            });

                            return results;
                        } catch (error) {
                            console.error("Error filtering options:", error);
                            return []; // Return an empty array in case of error
                        }
                    }}

                    isOptionEqualToValue={(option, value) => option.id === value.id}

                    fullWidth={true}
                    onChange={(e, value) => {
                        console.log(value)
                        if (!value) return setTimeToEdit((old) => { return { ...old, kunde: null, prosjekt: null } })
                        setTimeToEdit((old) => { return { ...old, kunde: value.clientId, prosjekt: value } })
                    }
                    }
                    value={timeToEdit?.prosjekt}
                />

                <Autocomplete
                    id="aktivitet"
                    options={flattenedAktiviteter}
                    PaperComponent={CustomPaper}
                    groupBy={(option) => option.aktivitetId}
                    getOptionLabel={(option) => option.navn}
                    style={{ backgroundColor: "var(--input-background)", borderRadius: 5, }}
                    sx={customSxStyles}
                    renderInput={(params) => <TextField {...params} label={t("Activity", "Aktivitet")} />}
                    renderGroup={(params, index) => {
                        const groupName = idToNameMapping[params.group];
                        return (
                            <div key={`${params.group}-${index}`} style={{ background: "var(--input-background)" }}>
                                <p className="orange" style={{ marginLeft: 10 }}>{groupName}</p>
                                <p className="stretch-width" style={{ background: "var(--input-background)", padding: "10px 5px", listStyle: 'none', margin: 0 }}>
                                    {params.children}
                                </p>
                            </div>
                        )
                    }}

                    filterOptions={(options, { inputValue }) => {
                        try {
                            let results = [];
                            let lowerCaseInputValue = inputValue.toLowerCase(); // Convert input value to lowercase for case-insensitive comparison

                            options.forEach(option => {
                                // Convert clientName and navn to lowercase and check if they include the lowercase input value
                                if (option && option.aktivitetName && option.aktivitetName.toLowerCase().includes(lowerCaseInputValue)) {
                                    results.push(option);
                                }
                                else if (option && option.navn && option.navn.toLowerCase().includes(lowerCaseInputValue)) {
                                    results.push(option);
                                }
                            });

                            return results;
                        } catch (error) {
                            console.error("Error filtering options:", error);
                            return []; // Return an empty array in case of error
                        }
                    }}

                    isOptionEqualToValue={(option, value) => option.id === value.id}

                    fullWidth={true}
                    onChange={(e, value) => {
                        console.log(value)
                        if (!value) return setTimeToEdit((old) => { return { ...old, aktivitet: null, underAktivitet: null } })
                        setTimeToEdit((old) => { return { ...old, aktivitet: value.aktivitetId, underAktivitet: value } })
                    }
                    }
                    value={timeToEdit?.underAktivitet}
                />

                <div className="column small-gap">
                    <CustomInput label={t("Comment", "Kommentar")} value={timeToEdit.kommentar} onChange={(e) => setTimeToEdit({ ...timeToEdit, kommentar: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h3 className="orange">{t("Date", "Dato")}</h3>
                    <CustomInput
                        type={"date"}
                        noIcon={true}
                        onChange={(e) => {
                            // Check if the input value is a valid date string
                            const inputValue = e.target.value;
                            const newDate = new Date(inputValue);
                            if (!isNaN(newDate.getTime())) { // Check if the date is valid
                                const oldDate = timeToEdit.startDate || new Date();
                                newDate.setHours(oldDate.getHours(), oldDate.getMinutes());
                                setTimeToEdit({ ...timeToEdit, startDate: newDate });
                            } else {
                                // Handle invalid date input here (optional)
                                console.log("Invalid date input");
                                // For example, you might want to revert to a default state or display an error message
                            }
                        }}
                        value={formatDateForInput(
                            timeToEdit.startDate
                                ? createDateOutOfFirebaseTimestamp(timeToEdit.startDate)

                                : new Date())
                        }
                    />

                </div>
                <div className="row small-gap wrap-on-phone">
                    <div className="row small-gap stretch-width">
                        <CustomInput
                            label={t("From", "Fra")}
                            noIcon={true}
                            type={"time"}
                            className="noInputIconInteractionOnPhone"
                            onChange={(e) => {
                                const newTime = e.target.value.split(':');
                                const currentStartDate = timeToEdit.startDate || new Date();
                                currentStartDate.setHours(parseInt(newTime[0]), parseInt(newTime[1]));
                                setTimeToEdit(prev => {
                                    let newEndDate = new Date(currentStartDate);
                                    if (prev.endDate) {
                                        newEndDate.setHours(prev.endDate.getHours(), prev.endDate.getMinutes());
                                        if (newEndDate <= currentStartDate) {
                                            newEndDate.setDate(newEndDate.getDate() + 1);
                                        }
                                    } else {
                                        newEndDate = new Date(currentStartDate.getTime() + 3 * 60 * 60 * 1000);  // Default 3-hour difference
                                    }
                                    return { ...prev, startDate: currentStartDate, endDate: newEndDate };
                                });
                            }}
                            value={
                                timeToEdit.startDate
                                    ? createDateOutOfFirebaseTimestamp(timeToEdit.startDate)
                                        .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                    : new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                            }


                        />


                        <CustomInput
                            label={t("To", "Til")}
                            className="noInputIconInteractionOnPhone"
                            type={"time"}
                            onChange={(e) => {
                                const newTime = e.target.value.split(':');
                                const currentEndDate = new Date(timeToEdit.startDate);
                                currentEndDate.setHours(parseInt(newTime[0]), parseInt(newTime[1]));
                                if (currentEndDate.getTime() <= timeToEdit.startDate.getTime()) {
                                    currentEndDate.setDate(currentEndDate.getDate() + 1);
                                }
                                setTimeToEdit(prev => ({ ...prev, endDate: currentEndDate }));
                            }}
                            value={
                                timeToEdit.endDate
                                    ? createDateOutOfFirebaseTimestamp(timeToEdit.endDate)
                                        .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                    : new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                            }
                            noIcon={true}
                        />
                    </div>
                    <TimetypeModalButton label={t("Type of Time", "Timetype")} values={timeToEdit.tilleg} onChange={(e) => setTimeToEdit({ ...timeToEdit, tilleg: e })} />
                    {/*tillegOptions &&
                        <CustomDropdown stretch label={t("Type of Time", "Timetype")} options={tillegOptions} selected={timeToEdit.tilleg} defaultValue={tillegOptions[0]}
                            onChange={(e) => {
                                console.log(e)
                                if (e) setTimeToEdit({ ...timeToEdit, tilleg: e })
                            }} />
                    */}
                </div>
                <div className="column">
                    {timeToEdit.extraTimes.map((time, index) => {
                        return (
                            <ExtraTime key={time.id} time={time} index={index} timeToEdit={timeToEdit} setTimeToEdit={setTimeToEdit} tillegOptions={tillegOptions} />
                        )
                    })
                    }
                    <div className="stretch-width flexApart">
                        <div />
                        <button onClick={() => {
                            setTimeToEdit(prev => ({ ...prev, extraTimes: [...prev.extraTimes, { id: createId(), timer: "00:00", tilleg: [tillegOptions[0]] }] }))
                        }}>{t("Add extra time", "Legg til ekstra tid")}<Add /></button>
                    </div>
                </div>
                <div className="row gap stretch-width">
                    <div className="column small-gap stretch-width">

                        <TimeInput
                            label={t("Pause", "Pause")}
                            //onClick={() => setPauseDialogOpen(true)}
                            //value={`${pauseHours}:${pauseMinutes}`} // Display the pause time in the input
                            //readOnly
                            value={timeToEdit.pause}
                            onChange={(e) => {
                                console.log(e);
                                //const [hours, minutes] = e.split(':');
                                setTimeToEdit((prev) => ({
                                    ...prev,
                                    pause: e,
                                }));
                            }}

                            noIcon={true}
                        />
                        <CheckboxInput value={timeToEdit.diett} label={t("Diet", "Diett")} onChange={(e) => setTimeToEdit({ ...timeToEdit, diett: e })} icon={FlightTakeoffRounded} />
                    </div>

                    <div className="row small-gap stretch-width wrap">
                        <div className="column small-gap stretch-width">
                            <CustomInput
                                label={t("Total time", "Total tid")}
                                value={totalTime} // Display the total time in the input
                                readOnly
                                noIcon={true}
                            />
                        </div>

                        <div className="column small-gap stretch-width">
                            <FakturerbarTid value={totalTime} onChange={(e) => setTimeToEdit({ ...timeToEdit, fakturerbareTimer: e })} t={t} />
                        </div>
                    </div>
                </div>



                {settings.komponenter.utstyr &&
                    <UtstyrSearchWithPopup value={timeToEdit?.utstyr} onChange={(e) => setTimeToEdit({ ...timeToEdit, utstyr: e })} />
                }

                {history.location.state?.timeToEdit && !history.location.state?.noDeleteButton && history.location.state?.timeToEdit.status !== "godkjent" &&
                    <button className="recording center" style={{ minWidth: 100 }} onClick={async () => {
                        console.log(timeToEdit)
                        console.log(history.location.state)
                        await deleteTimeFromDB(timeToEdit, user)
                        history.goBack()
                    }}>{t("Delete Time", "Slett Time")}</button>
                }


                <Dialog open={pauseDialogOpen} onClose={() => {
                    setPauseDialogOpen(false);
                }} fullScreen={true}>
                    <DialogContent
                        style={{
                            background: "#171a2173",
                            backdropFilter: "blur(8px)",
                        }}
                        fullScreen={true}
                        className="column small-gap stretch-width"
                    >
                        <div className="row">
                            <div className="column small-gap stretch-width">
                                <h3 className="orange">{t("From", "Fra")}</h3>
                                <CustomInput
                                    ref={fraRef}
                                    noIcon={true}
                                    type={"time"}
                                    className="big-time-input"
                                    onChange={(e) => {
                                        if (e.target.value === '' || e.target.value === null) return setTimeToEdit(prev => (
                                            {
                                                ...prev,
                                                startDate: roundDownToNearestHour(new Date()),
                                                //endDate: roundDownToNearestHour(getCurrentDatePlus10Seconds()),
                                            }
                                        ));

                                        const newTime = e.target.value.split(':');
                                        const currentStartDate = timeToEdit.startDate || new Date();
                                        currentStartDate.setHours(parseInt(newTime[0]), parseInt(newTime[1]));
                                        setTimeToEdit(prev => {
                                            let newEndDate = new Date(currentStartDate);
                                            if (prev.endDate) {
                                                newEndDate.setHours(prev.endDate.getHours(), prev.endDate.getMinutes());
                                                if (newEndDate <= currentStartDate) {
                                                    newEndDate.setDate(newEndDate.getDate() + 1);
                                                }
                                            } else {
                                                newEndDate = new Date(currentStartDate.getTime() + 3 * 60 * 60 * 1000);  // Default 3-hour difference
                                            }
                                            return { ...prev, startDate: currentStartDate, endDate: newEndDate };
                                        });
                                    }}
                                    value={
                                        timeToEdit.startDate
                                            ? createDateOutOfFirebaseTimestamp(timeToEdit.startDate)
                                                .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                            : new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                    }
                                />
                            </div>
                            <div className="column small-gap stretch-width">
                                <h3 className="orange">{t("To", "Til")}</h3>
                                <CustomInput
                                    ref={tilRef}
                                    type={"time"}
                                    className="big-time-input"
                                    onChange={(e) => {
                                        if (e.target.value === '' || e.target.value === null) return setTimeToEdit(prev => (
                                            {
                                                ...prev,
                                                //startDate: roundDownToNearestHour(new Date()),
                                                endDate: roundDownToNearestHour(getCurrentDatePlus10Seconds()),
                                            }
                                        ));
                                        const newTime = e.target.value.split(':');
                                        const currentEndDate = new Date(timeToEdit.startDate);
                                        currentEndDate.setHours(parseInt(newTime[0]), parseInt(newTime[1]));
                                        if (currentEndDate.getTime() <= timeToEdit.startDate.getTime()) {
                                            currentEndDate.setDate(currentEndDate.getDate() + 1);
                                        }
                                        setTimeToEdit(prev => ({ ...prev, endDate: currentEndDate }));
                                    }}
                                    value={
                                        timeToEdit.endDate
                                            ? createDateOutOfFirebaseTimestamp(timeToEdit.endDate)
                                                .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                            : new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                    }
                                    noIcon={true}
                                />
                            </div>
                        </div>

                        <div className="row gap stretch-width">
                            <div className="column small-gap stretch-width">
                                <h3 className="orange">{t("Pause", "Pause")}</h3>
                                <TimeInput
                                    //forwardRef={pauseRef}
                                    className="big-time-input"
                                    //onClick={() => setPauseDialogOpen(true)}
                                    value={timeToEdit.pause} // Display the pause time in the input
                                    //readOnly
                                    onChange={(e) => {
                                        console.log(e);
                                        //const [hours, minutes] = e.split(':');
                                        setTimeToEdit((prev) => ({
                                            ...prev,
                                            pause: e,
                                        }));
                                    }}
                                    noIcon={true}
                                />
                            </div>

                            <div className="row small-gap stretch-width wrap">
                                <div className="column small-gap stretch-width">
                                    <h3 className="orange">{t("Total time", "Timer")}</h3>
                                    <CustomInput
                                        className="big-time-input"
                                        style={{ fontSize: 30 }}
                                        value={totalTime} // Display the total time in the input
                                        readOnly
                                        noIcon={true}
                                    />
                                </div>
                                <div className="column small-gap stretch-width">
                                    <FakturerbarTid value={totalTime} onChange={(e) => setTimeToEdit({ ...timeToEdit, fakturerbareTimer: e })} t={t} />
                                </div>
                            </div>
                        </div>


                        <div className="row small-gap padding center stretch-width">
                            <button
                                style={{
                                    backgroundColor: "var(--input-background)"
                                }}
                                onClick={() => {
                                    setPauseDialogOpen(false);
                                }}
                            >
                                {t("Done", "Ferdig")}
                            </button>
                        </div>
                    </DialogContent>
                </Dialog>

            </div>
        </DefaultWrapper>
    )
}


function TimeInput({ value, onChange, className, onClick, forwardRef, label }) {
    ///const [time, setTime] = useState({ hours: '00', minutes: '00' });
    const time = value || { hours: '00', minutes: '00' };

    const handleTimeChange = (event) => {
        const [hours, minutes] = event.target.value.split(':');
        const formattedHours = String(Number(hours)).padStart(2, '0');
        const formattedMinutes = String(Number(minutes)).padStart(2, '0');

        //setTime({ hours: formattedHours, minutes: formattedMinutes });

        // If onChange is provided, call it with the new time object
        if (onChange) {
            onChange({ hours: formattedHours, minutes: formattedMinutes });
        }
    };

    return (
        <CustomInput
            label={label}
            ref={forwardRef ? forwardRef : null}
            className={className}
            type="time"
            value={`${time.hours}:${time.minutes}`}
            onChange={handleTimeChange}
            pattern="(?:[0-9]{1,2}):[0-5][0-9]"
            title="Enter time in HH:mm format"
            noIcon={true}
            onClick={(e) => {
                if (onClick) onClick(e);
            }}
        />
    );
}



const FakturerbarTid = ({ value, onChange, t }) => {
    const [time, setTime] = useState({ hours: '00', minutes: '00' });
    const [manuallyEdited, setManuallyEdited] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (!manuallyEdited && value) {
            const [hours, minutes] = parseTime(value);
            setTime({ hours, minutes });
        }
    }, [value, manuallyEdited]);

    const parseTime = (input) => {
        const parts = input.split(':');
        let hours = parts[0].slice(0, 2); // Take only the first 2 digits for hours
        let minutes = parts.length > 1 ? parts[1].slice(0, 2) : '00'; // Handle case where no minutes provided

        // Ensure the input numbers are valid, defaulting to '00' if not
        hours = isNaN(parseInt(hours, 10)) ? '00' : hours;
        minutes = isNaN(parseInt(minutes, 10)) ? '00' : minutes;

        return [hours, minutes];
    };

    const handleTimeChange = (event) => {
        const cursorPosition = event.target.selectionStart;
        const inputValue = event.target.value;
        const [inputHours, inputMinutes] = parseTime(inputValue);

        // Current max time
        const [maxHours, maxMinutes] = parseTime(value);

        // Validate hours and minutes
        const formattedHours = Number(inputHours) > Number(maxHours) ? maxHours : inputHours.padStart(2, '0');
        const formattedMinutes = Number(inputHours) === Number(maxHours) && Number(inputMinutes) > Number(maxMinutes) ? maxMinutes : inputMinutes.padStart(2, '0');

        // Update state with formatted time
        setTime({ hours: formattedHours, minutes: formattedMinutes });
        setManuallyEdited(true);

        if (onChange) {
            onChange({ hours: formattedHours, minutes: formattedMinutes });
        }

        // Restore cursor position
        setTimeout(() => {
            inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };

    return (
        <CustomInput
            label={t("Billable time", "Fakturerbar tid")}
            value={`${time.hours}:${time.minutes}`}
            onChange={handleTimeChange}
            pattern="(?:[0-9]{1,2}):[0-5][0-9]"
            ref={inputRef}
        />
    );
};




function roundDownToNearestHour(date) {
    if (!(date instanceof Date)) {
        throw new Error('Input must be a Date object');
    }
    return new Date(Math.floor(date.getTime() / (1000 * 60 * 60)) * (1000 * 60 * 60));
}

function formatDateForInput(date) {
    return moment(date).tz('Europe/Oslo').format('YYYY-MM-DD');
}