import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { collection, doc, getDocs, onSnapshot, query } from 'firebase/firestore';

import { db } from '../../../firebase';
import { addSubscription, removeSubscription } from '../../../stores/subscriptionsSlice';
import { addColSubscriber } from '../../../stores/utils/subscriptionObserver';

// RS for Redux Subscriber - is used with subscriptionObserver.jsx
/**
 * 
 * @param {Object} param
 * @param {Function} param.selector - Redux selector function
 * @param {Object} param.payload - Redux payload
 * @param {String} param.path - Firestore path
 * @param {Function} param.setFunction - Redux set function
 * @param {Function} param.updateFunction - Redux update function
 * @param {Function} param.removeFunction - Redux remove function
 * @param {Array} param.filters - Firestore filters
 * @param {String} param.key - Redux key
 * 
 * @returns {Object} - { data, isLoading, [key]: data }
 * 
 */
const useCollectionSubscriptionRS = ({
    // Redux
    selector = state => state,
    payload = {},
    addFunction = null,
    setFunction = null,
    updateFunction = null,
    removeFunction = null,
    idOnly = false,
    returnItemsByIdSelector = false,

    // Firestore
    path = '',
    filters = [],
    filterFunction = null,

    // State
    key = 'data',
    enabled = true,
}) => {
    const user = useSelector((state) => state.user.user);
    if (!enabled || !user) return { data: [], isLoading: false, [key]: [] };
    const data = useSelector(selector);
    const sel = returnItemsByIdSelector ? useSelector(returnItemsByIdSelector) : null;
    const returnItems = useMemo(() => {
        if (!returnItemsByIdSelector) return data;
        return data.map(id => sel[id]);
    }, [data]);

    const companyId = useSelector((state) => state.company.companyId);
    const fullPath = `Kompanier/${companyId}/${path}`;
    const isLoading = useSelector((state) => state?.subscriptions?.byId?.[fullPath]?.fetched || false);

    //const subscriptions = useSelector((state) => state.subscriptions.allIds);
    //const isLoading = subscriptions.includes(fullPath);

    useEffect(() => {
        if (!companyId) return console.error('No companyId found');

        addColSubscriber({
            // Redux
            payload,
            selector,
            addFunction,
            setFunction,
            updateFunction,
            removeFunction,
            idOnly,

            // Firestore
            path: fullPath,
            filters,
        })

        const subCheck = (event) => {
            const params = event.detail;
            const filterString = filters.map(filter => `${filter.field}${filter.operator}${filter.value}`).join('');
            const fullPathAndFilter = `${fullPath}${filterString}`;
            if (fullPathAndFilter !== params.id) return;

            params.functionToCall(fullPath);
        };
        document.addEventListener("checkForSubscribers", subCheck);

        return () => {
            document.removeEventListener("checkForSubscribers", subCheck);
        }
    }, []);

    return { data: returnItemsByIdSelector ? returnItems : data, isLoading, [key]: returnItemsByIdSelector ? returnItems : data };
};

export default useCollectionSubscriptionRS;

/**
 * 
 * @param {String} path 
 * @returns firebase collection or document reference
 * 
 * @example "Kompanier/10001/myData/myOtherData/TestData/anotherCol"
 * 
 */

export function convertToFirestorePath(path) {
    // Split the path into parts
    const pathParts = path.split("/");

    // Dynamically build the Firestore reference
    let ref;
    let isCollection = pathParts.length % 2 === 0

    // Iterate through the path parts
    if (isCollection) {
        // Even number of parts: It's a collection
        ref = collection(db, path);
    } else {
        // Odd number of parts: It's a document
        ref = doc(db, path);
    }

    return { ref, isCollection };
}
