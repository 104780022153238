import { collection, doc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../../../firebase";
import store from "../../../../stores/mainStore";
import { addSubscription } from "../../../../stores/subscriptionsSlice";


export const CollSub = ({
    // Redux
    selector = state => state,
    payload = {},
    addFunction = null,
    setFunction = null,
    updateFunction = null,
    removeFunction = null,
    idOnly = false,
    returnItemsByIdSelector = false,

    // Firestore
    path = '',
    filters = [],
    filterFunction = null,

    // State
    key = 'data',
    enabled = true,
}) => {
    const user = useSelector((state) => state.user.user);
    if (!enabled || !user) return { data: [], isLoading: false, [key]: [] };
    const data = useSelector(selector);
    const dispatch = useDispatch();
    const sel = returnItemsByIdSelector ? useSelector(returnItemsByIdSelector) : null;
    const returnItems = useMemo(() => {
        if (!returnItemsByIdSelector) return data;
        return data.map(id => sel[id]);
    }, [data]);

    const companyId = useSelector((state) => state.company.companyId);
    const fullPath = `Kompanier/${companyId}/${path}`;
    const fetched = useSelector((state) => state?.subscriptions?.byId?.[fullPath]?.fetched);
    const isLoading = !fetched;
    
    //const subscriptions = useSelector((state) => state.subscriptions.allIds);
    //const isLoading = subscriptions.includes(fullPath);

    useEffect(() => {
        if (!companyId) return console.error('No companyId found');

        let q = query(collection(db, fullPath));
        filters.forEach(filter => {
            const { field, operator, value } = filter;
            q = query(q, where(field, operator, value));
        });

        const fetchInitialData = async () => {
            if (fetched) return;
            dispatch(addSubscription(fullPath));
            try {

                const snapshot = await getDocs(q);
                //const state = store.getState()[selector];
                //console.log(snapshot.docs.map(doc => doc.data()), "PATH: ", fullPath, "FILTERS: ", filters)
                const state = selector(store.getState());
                if (addFunction) {
                    //console.log("STATE: ", state)
                    const newDocs = snapshot.docs.map(doc => doc.data()).filter(doc => state.some(item => (idOnly ? item : item.id) === doc.id));
                    newDocs.forEach(doc => dispatch(addFunction({ document: doc, ...payload })));
                    return
                }
                if (updateFunction) {
                    const newDocs = snapshot.docs.map(doc => doc.data()).filter(doc => state.some(item => (idOnly ? item : item.id) === doc.id));
                    newDocs.forEach(doc => {
                        dispatch(updateFunction({ document: doc, ...payload }))
                    });
                    return
                }
                dispatch(setFunction({ documents: snapshot.docs.map(doc => doc.data()), ...payload }));
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        };

        fetchInitialData();

        const sub = onSnapshot(q, (snapshot) => {
            if (setFunction && !updateFunction) return dispatch(setFunction({ documents: snapshot.docs.map(doc => doc.data()), ...payload }));

            snapshot.docChanges().forEach((change) => {
                console.log("NEW CHANGE", change, change.type === 'removed', removeFunction)
                const data = change.doc.data();
                const newPayload = { document: data, ...payload, id: data.id };
                if (change.type === 'added' && addFunction) {
                    dispatch(addFunction(newPayload)); // Handle new documents
                }
                if (change.type === 'modified' && updateFunction) {
                    dispatch(updateFunction(newPayload)); // Update store with changes
                }
                if (change.type === 'removed' && removeFunction) {
                    dispatch(removeFunction(newPayload)); // Handle removals
                }
            });
        });

        return () => sub();
    }, []);

    return { data: returnItemsByIdSelector ? returnItems : data, isLoading, [key]: returnItemsByIdSelector ? returnItems : data };
};



/**
 * 
 * @param {String} path 
 * @returns firebase collection or document reference
 * 
 * @example "Kompanier/10001/myData/myOtherData/TestData/anotherCol"
 * 
 */

export function convertToFirestorePath(path) {
    // Split the path into parts
    const pathParts = path.split("/");

    // Dynamically build the Firestore reference
    let ref;
    let isCollection = pathParts.length % 2 === 0

    // Iterate through the path parts
    if (isCollection) {
        // Even number of parts: It's a collection
        ref = collection(db, path);
    } else {
        // Odd number of parts: It's a document
        ref = doc(db, path);
    }

    return { ref, isCollection };
}