import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { addListeners, addNotificationReceivedListener, checkIfAuthenticated, checkPermissions, getAuthUser, getCompanyDetails, getCompanyId, getMessages, getUser, registerNotifications, requestPermissions, saveFCMTokenToUser } from './firebase'

import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import {
  IonAlert,
  IonApp,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle, home, calculator, person, chatboxEllipses, reader } from 'ionicons/icons';
import Tab1 from './pages/Tab1';

import Tab3 from './pages/login/Tab3';
import { IonBadge } from "@ionic/react";

// Pages
import ClientList from './pages/Klienter/clientList';
import CreateClient from './pages/Klienter/createClient';
import Rapporter from './pages/reports/rapporter';
import Rapport from './pages/reports/rapport';
import PdfPreview from './components/exportToPdf';
import PDFViewer from './pages/reports/pdfViewer';
import SenteRapporter from './pages/Klienter/projekter/senteRapporter';
import Canvas from './pages/reports/canvas';
import UtstyrsListe from './pages/utstyrsliste';
import ManageUtstyr from './pages/manageUtstyr';
import UtstyrsDetaljer from './pages/utstyrsdetaljer';
import Chat from './pages/chat/chat';
import { withRouter } from 'react-router-dom';

import { LoginPage } from './pages/login/loginPage';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import Kalkulator from './pages/kalkulator';
import useAuth from './useauth.js';
import NoteTakingPage from './pages/notater';

import AdministratorPage from './pages/administrator';
import Brukere from './pages/Ansatte/brukere';
import BrukerInstillinger from './pages/Ansatte/brukerInstillinger';
import ChooseCompany from './pages/chooseCompany';
import ReportSettings from './pages/reports/mal/rapport-maler';
import NyMal from './pages/reports/mal/nyMal';
import ReportInforing from './pages/reports/rapport-inforing';
import Header from './components/header';
import ReportInforing2 from './components/ReportInforing';
import CreateCompanyPage, { BrukerVilkår } from './pages/login/createCompany';
import DefaultWrapper from './components/defaultWrapper';
import RolleConfig from './pages/rolleConfig';
import Roller from './pages/roller';
import TimeTrackerMenu from './pages/timeføring/timetrackerMenu';
import ChatLobby from './pages/chat/chatLobby';
import CreateChatMenu from './pages/chat/chatCreate';
import ChatMessagesPage from './pages/chat/chatMessages';
import NotatLobby from './pages/notatLobby';
import EditCompanyPage from './pages/login/editCompany';
import { Capacitor, Plugins } from '@capacitor/core';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks'

//import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";


import { App as CapApp } from '@capacitor/app';
import Invitation from './pages/Ansatte/invitasjoner';
import TimeSettings from './pages/timeSettings';
import TimeAdminPage from './pages/timeføring/admin/timeAdmin';
import NyTid from './pages/timeføring/admin/newTime';
import ProjektOverview from './pages/Klienter/projekter/projektOverview';
import ProjektDokumenter from './pages/Klienter/projekter/projektDokumenter';
import RegisterUser from './pages/login/register';
import EndreProjekter from './pages/Klienter/projekter/EndreProjekter';
import HMSMeny from './pages/HMS/HMSMeny';
import RUHSkjema from './pages/HMS/RUH';
import PcHeader from './components/PCHeader';
import Geolokasjon from './pages/settings/geolocation';
import Instillinger from './pages/Instillinger';
import PermissionHandler from './pages/settings/generalSettings';
import PcHome from './pages/PCHome';
import AdminInstillinger from './pages/settings/admin/adminInstillinger';
import RapportInstillinger from './pages/settings/admin/rapportInstillinger';
import { NewTimePage } from "./pages/timeføring/newTime.jsx";
import Profile from './pages/profile';
import ProjektOversikt from './pages/Klienter/projekter/projektOversikt';
import VanligOversiktMenu from './pages/timeføring/vanligTimeoversikt';
import FørteUkeTimer from './pages/timeføring/førteUkeTimer.jsx';
import TimeforingAktivitet from './pages/settings/admin/timeføringsInstillinger/aktiviteter';
import TimeforingInstillinger from './pages/settings/admin/timeføringsInstillinger';
import Timegodkjenning from './pages/timeføring/admin/timegodkjenning.jsx';
import TimegodkjenningUser from './pages/timeføring/admin/timegodkjenningUser.jsx';
import WeekGodkjenning from './pages/timeføring/admin/WeekTimegodkjenning.jsx';
import ProjectList from './pages/Klienter/projekter/ProjectList.jsx';
import Moduler from './pages/modules/modules';
import ArbeidsplanTime from './pages/timeføring/arbeidsplanTime.jsx';
import EditTimeArbeidsplan from './pages/timeføring/admin/editTimeTimeplan';
import AdminTimeOverview from './pages/timeføring/admin/adminOversikt';
import Tillegg from './pages/settings/admin/timeføringsInstillinger/tillegg.jsx';
import { Network } from '@capacitor/network';
import Holiday from './pages/Absence/Holiday.jsx';
import ParentalLeave from './pages/Absence/ParentalLeave.jsx';
import SickLeave from './pages/Absence/SickLeave.jsx';
import SelfDeclaration from './pages/Absence/SelfDeclaration.jsx';
import TimeOff from './pages/Absence/TimeOff.jsx';
import CustomInput from './components/miniComponents/customInput.jsx';
import { CheckIfOnPC, UserContext } from './App';
import SigningWarning from './components/signingWarning.jsx';
import TemplatePreviewPage from './pages/reports/mal/templatePreviewPage';
import Client from './pages/Klienter/client';
//import hasUserId from './components/storageFunctions';

import Avvik from './pages/HMS/Avvik.jsx';
import SJA from './pages/HMS/SJA.jsx';
import Vernerunde from './pages/HMS/Vernerunde.jsx';
import SJAPDF from './pages/HMS/pdf/SJApdf';
import Avvikpdf from './pages/HMS/pdf/Avvikpdf';
import RUHPDF from './pages/HMS/pdf/RUHpdf.jsx';
import HMSOverview from './pages/HMS/HMSOverview';
import NavigationCX from './contexts/navigation';
import ResetPassword from './pages/login/resetPassword';
import HMSViewType from './pages/HMS/hmsViewType';
import { useSelector } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


export default function PathRouter() {
  const user = useSelector(state => state.user.user)
  const [atLogin, setAtLogin] = useState(false)
  const company = useSelector(state => state.company.company)


  const hasUserId = () => {
    //console.log(userContext?.user)
    return user
  }

  return (
    <IonReactRouter ionRouteDidChange={() => console.log("did")} swipeGesture={true}>
      <PcHeader />
      <SigningWarning />
      <NavigationCX />
      <ThemeProvider theme={darkTheme}>
        <div className='stretch-width stretch-height moz-height' style={{ position: 'relative' }}>
          <IonRouterOutlet>
            <Switch>
              {/*<ProtectedRoute path="/" component={Tab1} />*/}
              <Route exact path="/hjem">
                {hasUserId() ? (company?.id ? <Tab1 /> : <Redirect to="/company" />) : <Redirect to="/login" />}
              </Route>

              <Route exact path="/admin/instillinger/firma">
                <EditCompanyPage />
              </Route>

              <Route exact path="/admin/instillinger/">
                <AdminInstillinger />
              </Route>

              <Route exact path="/admin/instillinger/rapporter">
                <RapportInstillinger />
              </Route>

              <Route exact path="/admin/instillinger/timeforing">
                <TimeforingInstillinger />
              </Route>

              <Route exact path="/admin/instillinger/timeforing/tillegg">
                <Tillegg />
              </Route>

              <Route exact path="/admin/instillinger/timeforing/aktivitet/:id">
                <TimeforingAktivitet />
              </Route>

              <Route exact path="/absence">
                <DefaultWrapper />
              </Route>

              <Route exact path="/absence/holiday">
                <Holiday />
              </Route>

              <Route exact path="/absence/parental-leave">
                <ParentalLeave />
              </Route>

              <Route exact path="/absence/time-off">
                <TimeOff />
              </Route>

              <Route exact path="/absence/sick-leave">
                <SickLeave />
              </Route>

              <Route exact path="/absence/self-declaration">
                <SelfDeclaration />
              </Route>

              <Route exact path="/moduler">
                <Moduler />
              </Route>

              <Route path="/tab3">
                <Tab3 />
              </Route>

              <Route exact path="/profile">
                <Profile />
              </Route>

              <Route exact path="/kalkulator">
                <Kalkulator />
              </Route>

              <Route exact path="/clientList">
                <ClientList />
              </Route>

              <Route exact path="/manageClient">
                <CreateClient />
              </Route>

              <Route exact path="/client">
                <Client />
              </Route>

              <Route exact path="/prosjekt/edit">
                <ProjektOverview />
              </Route>

              <Route exact path="/prosjekter">
                <ProjectList />
              </Route>

              <Route exact path="/prosjekt/documents">
                <ProjektDokumenter />
              </Route>

              <Route exact path="/prosjekter/:id">
                <ProjektOverview />
              </Route>

              <Route exact path="/rapporter">
                <Rapporter />
              </Route>

              <Route exact path="/rapport/:id">
                <Rapport />
              </Route>


              <Route exact path="/rapport/:id/oppgave/:task">
                <ReportInforing2 />
              </Route>


              <Route exact path="/signering">
                <Canvas />
              </Route>


              <Route exact path="/signert">
                <PdfPreview />
              </Route>


              <Route exact path="/reportArchive">
                <SenteRapporter />
              </Route>


              <Route exact path="/reportPreview">
                <PdfPreview />
              </Route>


              <Route exact path="/pdfViewer">
                <PDFViewer />
              </Route>



              <Route exact path="/utstyrsliste">
                <UtstyrsListe />
              </Route>


              <Route exact path="/utstyr">
                <DefaultWrapper>
                  <ManageUtstyr />
                </DefaultWrapper>
              </Route>

              <Route exact path="/utstyr/:id">
                <UtstyrsDetaljer />
              </Route>

              <Route exact path="/login">
                {hasUserId() ? (company?.id ? <Redirect to="/hjem" /> : <Redirect to="/company" />) : <LoginPage />}
              </Route>

              <Route exact path="/glemt-passord">
                <ResetPassword />
              </Route>

              <Route exact path="/registrer">
                <RegisterUser />
              </Route>
              {/*
              <Route exact path="/chat">
                <Chat messages={[]} setIsInChatComponent={() => { }} />
              </Route>
            */}
              { /*
            <Route exact path="/chat/ny">
              <CreateChatMenu />
            </Route>
            */
              }
              {/*
              <Route exact path="/chats/:id">
                <ChatMessagesPage />
              </Route>
            */}
              {/*
            <Route exact path="/chatLobby">
              <ChatLobby />
            </Route>
            */}

              <Route exact path="/notater">
                <NotatLobby />
              </Route>

              <Route exact path="/notater/:id">
                <NoteTakingPage />
              </Route>


              <Route exact path="/admin">
                <AdministratorPage />
              </Route>

              <Route exact path="/brukere">
                <Brukere />
              </Route>

              <Route path="/brukere/:brukerId">
                <BrukerInstillinger />
              </Route>

              <Route exact path="/roller">
                <Roller />
              </Route>

              <Route exact path="/roller/:id">
                <RolleConfig />
              </Route>
              <Route exact path={"/switch-company"}>
                <ChooseCompany />
              </Route>

              <Route exact path="/company">
                {hasUserId() ? (company?.id ? <Redirect to="/hjem" /> : <ChooseCompany />) : <Redirect to="/login" />}
                {/*hasUserId() ? <ChooseCompany /> : <Redirect to="/login" />*/}
              </Route>

              <Route exact path="/reportSettings">
                <ReportSettings />
              </Route>

              <Route path="/reportSettings/:id">
                <NyMal />
              </Route>

              <Route exact path="/templates/:id/TemplatePreview}">
                <TemplatePreviewPage />
              </Route>

              <Route exact path="/timeoversikt">
                <VanligOversiktMenu />
              </Route>

              <Route exact path="/timeføring">
                <TimeTrackerMenu />
              </Route>

              <Route exact path="/timeføring/:id">
                <NewTimePage />
              </Route>

              <Route exact path="/arbeidsplan/:id">
                <ArbeidsplanTime />
              </Route>

              <Route exact path="/uke/:id">
                <FørteUkeTimer />
              </Route>

              <Route exact path="/timeSettings">
                <TimeSettings />
              </Route>

              <Route exact path="/timeAdmin">
                <AdminTimeOverview />
              </Route>

              <Route exact path="/arbeidsplan">
                <TimeAdminPage />
              </Route>

              <Route exact path="/timeAdmin/editTime">
                <EditTimeArbeidsplan />
              </Route>

              <Route exact path="/timeAdmin/nytid">
                <NyTid />
              </Route>

              <Route exact path="/timegodkjenning">
                <Timegodkjenning />
              </Route>

              <Route exact path="/timegodkjenning/uke">
                <TimegodkjenningUser />
              </Route>

              <Route exact path="/timegodkjenning/uke/:id">
                <WeekGodkjenning />
              </Route>

              <Route exact path="/cardscan">
                <DefaultWrapper />
              </Route>

              <Route exact path="/createCompany">
                <CreateCompanyPage />
              </Route>



              <Route path="/invitasjoner">
                <Invitation />
              </Route>

              <Route exact path="/hms">
                {CheckIfOnPC() ? <HMSOverview /> : <HMSMeny />}

              </Route>

              <Route exact path="/hms/sja/pdf">
                <SJAPDF />
              </Route>

              <Route exact path="/hms/sja/:id">
                <SJA />
              </Route>


              <Route exact path="/hms/ruh/pdf">
                <RUHPDF />
              </Route>

              <Route exact path="/hms/ruh/:id">
                <RUHSkjema />
              </Route>

              <Route exact path="/hms/avvik/pdf">
                <Avvikpdf />
              </Route>

              <Route exact path="/hms/avvik/:id">
                <Avvik />
              </Route>

              <Route exact path="/hms/:type">
                <HMSViewType />
              </Route>



              <Route exact path="/hms/vernerunde">
                <Vernerunde />
              </Route>

              <Route exact path="/hms/stoffkartotek">
                <DefaultWrapper />
              </Route>

              <Route exact path="/instillinger">
                <Instillinger />
              </Route>

              <Route exact path="/">
                {hasUserId() ? (company?.id ? <Redirect to="/hjem" /> : <Redirect to="/company" />) : <Redirect to="/login" />}
              </Route>



              <Route exact path="/PermissionHandler">
                <PermissionHandler />
              </Route>



              <Route exact path="*">
                {hasUserId() ? (company?.id ? <Redirect to="/hjem" /> : <Redirect to="/company" />) : <Redirect to="/login" />}
              </Route>
            </Switch>
          </IonRouterOutlet>
        </div>
      </ThemeProvider>


      <div id="hidden" style={{
        display: "none"
      }}></div>


    </IonReactRouter>
  )
}