import React, { useContext, useEffect, useRef, useState } from 'react';
import BigButton from '../components/bigButton';
import { clipboard, person, people, hammer, calculator, reader } from 'ionicons/icons';
//import {} from 'react-dom'

import '../css/hjem.css';
import Background from '../components/miniComponents/background';
import ButtonListButton from '../components/miniComponents/buttonListButton';

//icons
import { ReactComponent as ChatIcon } from '../assets/icons/material-symbols_chat.svg';
import { ReactComponent as RapportIcon } from '../assets/icons/material-symbols_contact-page.svg';
import { ReactComponent as Warning } from '../assets/icons/material-symbols_warning-rounded.svg';
import { ReactComponent as UtstyrIcon } from '../assets/icons/mdi_wrench.svg';
import { ReactComponent as KalkulatorIcon } from '../assets/icons/mdi_calculator.svg';
import { ReactComponent as NotaterIcon } from '../assets/icons/mdi_notebook.svg';
import { ReactComponent as AdminIcon } from '../assets/icons/eos-icons_admin.svg';
import { ReactComponent as UserIcon } from '../assets/icons/material-symbols_person.svg';
import { ReactComponent as AlarmIcon } from '../assets/icons/mdi_alarm.svg';
import { ReactComponent as WorkIcon } from '../assets/icons/material-symbols_work.svg';
import { ReactComponent as TimeOversikt } from '../assets/icons/time-paper.svg';
import CircleIcon from "../components/miniComponents/circleIcon";

import { useHistory } from 'react-router';
import { CropImage } from '../components/createSmallImage';
import DefaultWrapper from '../components/defaultWrapper';
import { createDateOutOfFirebaseTimestamp, getDay, getDayInMonth, getMonth } from './timeføring/timeføring';
import { getAuthUser, getCompanyDetails, getCompanyId, getFirmSettings, getRecordedTimeFromDB } from '../firebase';
import Timer from '../components/timetracking/timer';
import CustomInput from '../components/miniComponents/customInput';
import { UserContext } from '../App';
import { checkRolesForPermission } from '../components/utils';

import Logo from '../assets/logo.png';
import { useTranslation } from 'react-i18next';
import { getCompanyUser, getCompanyUserRoles } from '../database/users';
import { Construction } from '@mui/icons-material';
import { useSelector } from 'react-redux';



function Tab1() {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(state => state.user.user);
  const companyUser = useSelector(state => state.user.companyUser);

  function navigateToNewPage(path) {
    history.push(path);
  }

  const [time, setTime] = useState({})

  const [previousRecordings, setPreviousRecordings] = useState([])
  const [timeChoiseModalInfo, setTimeChoiseModalInfo] = useState({
    open: false,
    startDate: new Date(),
    endDate: new Date(),
    title: "",
  })
  const [moduleSettings, setModuleSettings] = useState({})

  const [permitAdminButton, setPermitAdminButton] = useState(false)

  const [countUp, setCountUp] = useState(false)

  useEffect(() => {
    const onUpdate = (e) => {
      console.log("settings changed")
      setModuleSettings(e);
    }
    const getSettings = async () => {
      await getFirmSettings("moduleSettings", onUpdate);
    }
    getSettings();
  }, []);

  useEffect(() => {
    const onUpdate = (data) => {
      //console.log(data);  // Add this
      setPreviousRecordings(data)
    }

    getRecordedTimeFromDB(onUpdate)
  }, [])

  useEffect(() => {
    // If we find a recording that has no end date, we set the timer to count up
    let found = previousRecordings.find((recording) => {
      return !recording.endDate
    })

    if (found) {
      found.startDate = createDateOutOfFirebaseTimestamp(found.startDate)
      setTime({ ...time, ...found })
      setCountUp(true)
    }

    if (!found) {
      setTime({})
      setCountUp(false)
    }
  }, [previousRecordings])

  const createSmallImage = (inputEvent) => {
    const file = inputEvent.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      console.log(e.target.result)

      const loadImage = () => {
        const src = e.target.result;
        // save to "this" to remove "load" handler on unmount



        let image = new Image();
        image.src = src;

        console.log(image)
        const handleLoad = (e) => {
          CropImage({
            square: { width: 24, height: 24 },
            image: image,
          })
        }

        image.addEventListener('load', handleLoad);
        //this.image.crossOrigin = 'Anonymous';
      }
      loadImage()

    }
  }


  useEffect(() => {
    if (companyUser?.permissions) {
      console.log(companyUser?.permissions)
      //checkRolesForPermission(userContext?.companyUser?.roles, ["lageMaler", "admin", "redigereMaler", "sletteMaler", "seUtstyr", "lageUtstyr", "redigereUtstyr", "sletteUtstyr", "seAndreSineTimer", "kundeOversikt", "invitereBrukere", "fjerneBruklere", "redigereBrukere"])
      setPermitAdminButton(checkRolesForPermission(companyUser?.permissions, ["lageMaler", "admin", "redigereMaler", "sletteMaler", "seUtstyr", "lageUtstyr", "redigereUtstyr", "sletteUtstyr", "seAndreSineTimer", "kundeOversikt", "invitereBrukere", "fjerneBruklere", "redigereBrukere"]))
    }
  }, [companyUser?.permissions, companyUser])

  const [company, setCompany] = useState({});
  const [open, closed] = useState(true);
  const [companyId, setCompanyId] = useState(() => {
    return window.localStorage.getItem("companyId") || null;
  });


  async function getCompany() {
    const deails = await getCompanyDetails();
    setCompany(deails);
    console.log(deails);
  }

  useEffect(() => {
    if (history.location.pathname === "/login" || history.location.pathname === "/registrer" || history.location.pathname === "/company" || history.location.pathname === "/createCompany") return;
    getCompany();
  }, [user]);


  return (
    <DefaultWrapper header={true} noBackButton={true} noMenuButton={true}>
      <div className='content-ny'>
        <div className=' row flexApart'>
          <img src={company?.logo || Logo
          } alt="logo" className="logo center clickable" style={{
            maxWidth: "160px",
            maxHeight: 36,
            height: 36,
            objectFit: 'contain'
          }} />
          {permitAdminButton && <button onClick={() => navigateToNewPage("./admin")}>
            <AdminIcon></AdminIcon>
            <p>{t("Administrator", "Administrer")}</p>
          </button>
          }
        </div>


        <div className='row flexApart' >
          <button className="row center-column time-card stretch-width" onClick={() => (navigateToNewPage("./timeføring"))}>
            <CircleIcon good={!countUp} recording={countUp} icon={AlarmIcon} />

            <div className="column stretch-width wrap">
              <div className="row flexApart stretch-width wrap">
                <h2> {time.title || "Tar opp Timer"}</h2>
                <h2>{getDayInMonth()}, {getMonth(new Date(), t)}</h2>
              </div>

              <div className="row flexApart wrap">
                <p>{t("Time", "Tid")}:</p>
                <Timer startDate={time.startDate} countUp={countUp} />
              </div>
            </div>
          </button>
        </div>

        <div className=' row wrap center button-list'>
          <ButtonListButton title={t("Reports", "Rapporter")} description={t("2 new reports is waiting to be sent", "2 Rapporter venter avsendelse")} navigateTo={"./rapporter"} icon={RapportIcon} moduleDisabled={moduleSettings?.rapportModul !== true} />
          <ButtonListButton title={t("Equipment list", "Utstyrsliste")} description={t("Not any updates to tools from last check", "Ikke noe nytt utstyr fra siste sjekk")} navigateTo={"./utstyrsliste"} icon={UtstyrIcon} moduleDisabled={moduleSettings?.utstyrModul !== true} />
          <ButtonListButton title={t("Projects", "Prosjekter")} navigateTo={"./prosjekter"} icon={WorkIcon} moduleDisabled={moduleSettings?.prosjektModul !== true} />
          <ButtonListButton title={t("Customers", "Kunder")} permissions={"kundeOversikt"} navigateTo={"./clientList"} icon={WorkIcon} moduleDisabled={moduleSettings?.kundeModul !== true} />
          {/*<ButtonListButton title={"HMS"} navigateTo={"./hms"} icon={RapportIcon} />*/}
          {localStorage?.getItem('companyId') === '10001' && <ButtonListButton title={"Kalkulator"} navigateTo={"./kalkulator"} icon={KalkulatorIcon} />}
          <ButtonListButton title={t("Notes", "Notater")} description={t("Remember to take notes!","Husk å ta notater!")} navigateTo={"./notater"} icon={NotaterIcon} />
          <ButtonListButton title={t("Time Management", "Timeføring")} navigateTo={"./timeføring"} icon={AlarmIcon} moduleDisabled={moduleSettings?.timeføringModul !== true} />
          <ButtonListButton title={t("Health and Safety", "HMS")} navigateTo={"./hms"} icon={Construction} moduleDisabled={moduleSettings?.hmsModule !== true} />
          {/*<ButtonListButton title={"Time Oversikt"} navigateTo={"./timeoversikt"} icon={TimeOversikt} />*/}
          {/*<ButtonListButton title={t("Settings", "Innstillinger")} navigateTo={"./instillinger"} icon={AdminIcon} />*/}
        </div>
        <div id='hidden' />
      </div>
    </DefaultWrapper>
  );
};

export default Tab1;
