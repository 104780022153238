// store/tasksSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createDateOutOfFirebaseTimestamp } from '../pages/timeføring/timeføring';
import { createId, uploadTimeToDbRS } from '../firebase';
import { setHMSReport } from '../database/hms';
import store from './mainStore';
import { convertToLocalTimeString, getMonthId, getWeekId } from '../pages/timeføring/utils/utils';
import { getTimeById } from '../database/timetracking';


export const createDefaultTime = () => {
    return {
        id: createId(),
        startDate: null,
        endDate: null,
        title: "",
        pause: false,
        tilleg: [{
            id: "normal",
            name: "Normal",
            prosent: 0,
        }],
        stoppet: false,
        kunde: null,
        prosjekt: null,
        bruker: null,
        user: null,
        kommentar: "",
    }
};

export const setRcTimeAsync = createAsyncThunk('time/setRcTime', async (dataPassed, { dispatch }) => {
    const prevRcTimes = store.getState().time.prevRcTimes
    const rcTime = {
        ...createDefaultTime(),
        ...dataPassed,
        startDate: dataPassed?.startDate ? new Date(dataPassed.startDate) : null,
        endDate: dataPassed?.endDate ? new Date(dataPassed.endDate) : null,
    };

    dispatch(setRcTime(rcTime))

    if (rcTime.endDate) {
        dispatch(addTime({ document: rcTime }))
        dispatch(setPrevRcTimes({ documents: prevRcTimes.filter(t => t.id !== rcTime.id) }))

        if (prevRcTimes.filter(t => t.id !== rcTime.id).length === 0) {
            dispatch(setRcTime(createDefaultTime()))
        } else {
            dispatch(setRcTime(prevRcTimes.filter(t => t.id !== rcTime.id)[0]))
        }
    }

    if (rcTime.startDate) {
        console.log("Uploading Time", rcTime, rcTime.startDate)
        await uploadTimeToDbRS({
            data: rcTime
        });
    };
});

export const checkIfRCTimeCompleted = createAsyncThunk('time/setRcTime', async (dataPassed, { dispatch }) => {
    const rcTime = dataPassed
    const docData = await getTimeById(rcTime.id)
    if (docData?.endDate) {
        dispatch(setRcTime(createDefaultTime()))
    }
});


const TimeSlice = createSlice({
    name: 'time',
    initialState: {
        rcTime: {...createDefaultTime()},
        prevRcTimes: [],

        allTimes: {
            byId: {},
            allIds: []
        },
        months: {
            byId: {},
            allIds: []
        },
        weeks: {
            byId: {},
            allIds: []
        },
    },

    reducers: {
        setRcTime: {
            reducer: (state, action) => {
                console.log("setRcTime", action.payload)
                state.rcTime = action.payload;
            }
        },
        setPrevRcTimes: {
            reducer: (state, action) => {
                state.prevRcTimes = action.payload;
                console.log("setPrevRcTimes", action.payload)
                if (action.payload.length > 0) {
                    state.rcTime = action.payload[0];
                }
            },
            prepare: ({ documents }) => {
                return {
                    payload: documents
                };

            }
        },

        completeTime: {
            reducer: (state, action) => {
                const { document } = action.payload;
            },
            prepare: ({ document }) => {
                return {
                    payload: {
                        document: document
                    }
                };
            }
        },

        setTimes: {
            reducer: (state, action) => {
                const { documents } = action.payload;

                documents.forEach(document => {
                    const preparedDocument = document
                    const weekId = getWeekId(new Date(document.startDate));
                    const monthId = getMonthId(new Date(document.startDate));
                    const date = new Date(document.startDate);

                    // Add the time to allTimes
                    state.allTimes.byId[document.id] = preparedDocument;
                    if (!state.allTimes.allIds.includes(document.id)) {
                        state.allTimes.allIds.push(document.id);
                    }

                    // Add the time to the respective week and month
                    addToWeek(state, weekId, document, date);
                    addToMonth(state, monthId, weekId);
                });
            },
            prepare: ({ documents }) => {
                return {
                    payload: {
                        documents: documents
                    }
                };
            }
        },

        // Add a new time
        addTime: {
            reducer: (state, action) => {
                console.log("addTime", action.payload);
                const { document } = action.payload;
                const weekId = getWeekId(new Date(document.startDate));
                const monthId = getMonthId(new Date(document.startDate));
                const date = new Date(document.startDate);
                // Add the new time to allTimes

                state.allTimes.byId[document.id] = document;
                if (!state.allTimes.allIds.includes(document.id)) state.allTimes.allIds.push(document.id);

                // Check if RC Time - remove if so.
                const rcTime = state.rcTime;
                if (rcTime.id === document.id) {
                    state.rcTime = createDefaultTime();
                }

                // Add the time to the respective week and month
                addToWeek(state, weekId, document, date);
                addToMonth(state, monthId, weekId);
            },
            prepare: ({ document }) => {
                return {
                    payload: {
                        document: document
                    }
                };
            }
        },

        // Update an existing time
        updateTime: {
            reducer: (state, action) => {
                const { document } = action.payload;
                const weekId = getWeekId(new Date(document.startDate));
                const monthId = getMonthId(new Date(document.startDate));
                const date = new Date(document.startDate);

                // Update the time in allTimes
                state.allTimes.byId[document.id] = document;

                // Ensure the time is correctly associated with the week and month
                addToWeek(state, weekId, document, date);
                addToMonth(state, monthId, weekId);
            },
            prepare: ({ document }) => {
                return {
                    payload: {
                        document: document
                    }
                };
            }
        },

        // Remove a time
        removeTime: {
            reducer: (state, action) => {
                const { document } = action.payload;
                const weekId = getWeekId(new Date(document.startDate));
                const monthId = getMonthId(new Date(document.startDate));

                // Remove from allTimes
                delete state.allTimes.byId[document.id];
                state.allTimes.allIds = state.allTimes.allIds.filter(id => id !== document.id);

                // Remove from the week
                removeFromWeek(state, weekId, document.id);

                // Optionally, clean up the week and month if they're empty
                cleanUpWeekAndMonth(state, weekId, monthId);
            },
            prepare: ({ document }) => {
                return {
                    payload: {
                        document: document
                    }
                };
            }
        }
    }
});

export const { setTimes, addTime, updateTime, removeTime, setPrevRcTimes, setRcTime } = TimeSlice.actions;
export default TimeSlice.reducer;


const prepareDocument = (document) => {
    return {
        ...document,
        startDate: createDateOutOfFirebaseTimestamp(document.startDate),
        endDate: createDateOutOfFirebaseTimestamp(document.endDate),
        dato: createDateOutOfFirebaseTimestamp(document.dato),
        bruker: {
            ...document.bruker,
            dato: createDateOutOfFirebaseTimestamp(document.bruker?.dato)
        }
    };
};

const addToWeek = (state, weekId, timeDocument, date) => {
    if (!state.weeks.byId[weekId]) {
        state.weeks.byId[weekId] = { id: weekId, times: [], date: date };
        state.weeks.allIds.push(weekId);
    }



    const existingIndex = state.weeks.byId[weekId].times.findIndex(time => time.id === timeDocument.id);

    if (existingIndex === -1) {
        state.weeks.byId[weekId].times.push(timeDocument);
    } else {
        // Replace the existing time document
        state.weeks.byId[weekId].times[existingIndex] = timeDocument;
    }
};

// Helper to update the time in a week (alternative to replacing everything)
const updateInWeek = (state, weekId, timeDocument) => {
    const week = state.weeks.byId[weekId];
    if (week) {
        const index = week.times.findIndex(time => time.id === timeDocument.id);
        if (index !== -1) {
            week.times[index] = timeDocument; // Update the specific time
        }
    }
};

// Helper to add a week to a month
const addToMonth = (state, monthId, weekId) => {
    if (!state.months.byId[monthId]) {
        state.months.byId[monthId] = { id: monthId, weeks: [] };
        state.months.allIds.push(monthId);
    }
    if (!state.months.byId[monthId].weeks.includes(weekId)) {
        state.months.byId[monthId].weeks.push(weekId);
    }
};

// Helper to remove a time from a week by its ID
const removeFromWeek = (state, weekId, timeId) => {
    const week = state.weeks.byId[weekId];
    if (week) {
        week.times = week.times.filter(time => time.id !== timeId);
    }
};

// Helper to clean up empty weeks and months
const cleanUpWeekAndMonth = (state, weekId, monthId) => {
    const week = state.weeks.byId[weekId];
    if (week && week.times.length === 0) {
        delete state.weeks.byId[weekId];
        state.weeks.allIds = state.weeks.allIds.filter(id => id !== weekId);

        const month = state.months.byId[monthId];
        if (month) {
            month.weeks = month.weeks.filter(id => id !== weekId);
            if (month.weeks.length === 0) {
                delete state.months.byId[monthId];
                state.months.allIds = state.months.allIds.filter(id => id !== monthId);
            }
        }
    }
};

