import CircleIcon from "../miniComponents/circleIcon";
import Timer from "./timer";
import { ReactComponent as AlarmIcon } from '../../assets/icons/mdi_alarm.svg';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getDay, getDayInMonth, getMonth, CustomPaper, customSxStyles } from "../../pages/timeføring/timeføring";
import { useFetchSingleEditableRS, useSingleFetchEditableRS } from "../utils/hooks/RSFetchSingleEditable";
import { checkIfRCTimeCompleted, completeTime, createDefaultTime, setPrevRcTimes, setRcTime, setRcTimeAsync } from "../../stores/timeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { checkForTimeOverlap, createId, uploadTimeToDb, uploadTimeToDbRS } from "../../firebase";
import ButtonOnlyHitOnce from "../miniComponents/buttonOnlyHitOnce";
import { SquareRounded } from "@mui/icons-material";

import { ReactComponent as PauseIcon } from '../../assets/icons/mdi_hamburger.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/ph_play-fill.svg';
import { useTranslation } from "react-i18next";
import useCollectionSubscriptionRS from "../utils/hooks/RSfirebaseCollectionSubscriber";
import { storeEditable } from "../utils/hooks/storeSingleEditable";

export function StartAndStopTimer({ flattenedOptions = [] }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const ts = useSelector(state => state.time.prevRcTimes)


    const { rcTimes, isLoading } = useCollectionSubscriptionRS({
        selector: (state) => state.time.prevRcTimes,
        path: `tid/${user.uid}/timer`,
        setFunction: setPrevRcTimes,
        key: "rcTimes",
        payload: {},
        // Only the once created by the user
        filters: [
            { field: 'endDate', operator: '==', value: null }
        ]
    });
    const { time, setTime } = storeEditable({
        selector: state => state.time.rcTime,
        setFunction: setRcTimeAsync,
        key: "time"
    })

    useEffect(() => {
        dispatch(checkIfRCTimeCompleted())
    }, [])

    return (
        <div className="column">
            <div className="column stretch-width time-card">
                <div className="row flexApart">
                    <h2>{getDay(t)}</h2>
                    <h2>{getDayInMonth(new Date(), t)}, {getMonth(new Date(), t)}</h2>
                </div>
                <div className="row flexApart wrap-on-phone">
                    <div className="row stretch-width center">
                        <CircleIcon good={time?.startDate} recording={time?.startDate} icon={AlarmIcon} />
                        <Autocomplete
                            PaperComponent={CustomPaper}
                            id="Timeføring"
                            value={time?.prosjekt || { navn: "" }}
                            options={flattenedOptions}
                            filterOptions={(options, { inputValue }) => {
                                try {
                                    let results = [];
                                    let lowerCaseInputValue = inputValue.toLowerCase(); // Convert input value to lowercase for case-insensitive comparison

                                    options.forEach(option => {
                                        // Convert clientName and navn to lowercase and check if they include the lowercase input value
                                        if (option && option.clientName && option.clientName.toLowerCase().includes(lowerCaseInputValue)) {
                                            results.push(option);
                                        }
                                        else if (option && option.navn && option.navn.toLowerCase().includes(lowerCaseInputValue)) {
                                            results.push(option);
                                        }
                                    });

                                    return results;
                                } catch (error) {
                                    console.error("Error filtering options:", error);
                                    return []; // Return an empty array in case of error
                                }
                            }}
                            groupBy={(option) => option.clientName}
                            getOptionLabel={(option) => option?.nummer ? option?.nummer + " - " + option.navn : option.navn}
                            style={{ backgroundColor: "var(--input-background)", borderRadius: 5, }}
                            sx={{ ...customSxStyles, minWidth: 200, backgroundColor: "var(--input-background)", backgroundClip: "var(--input-background)" }}
                            renderInput={(params) => <TextField {...params} label={t("Project", "Prosjekt")} />}
                            renderGroup={(params) => (
                                <div key={params.key} style={{ background: "var(--input-background)" }}>
                                    <p className="orange" style={{ marginLeft: 10 }}>{params.group}</p>
                                    <p className="stretch-width" style={{ background: "var(--input-background)", padding: "10px 5px", listStyle: 'none', margin: 0 }}>
                                        {params.children}
                                    </p>
                                </div>
                            )}
                            fullWidth={true}
                            onChange={async (e, value) => {
                                console.log(value);
                                if (!value) return setTime((old) => { return { ...old, kunde: null, prosjekt: null }; });
                                //const getKundeById = await getClientById(value.clientId)
                                //const kundeData = getKundeById.data
                                //console.log(kundeData)
                                setTime((old) => { return { ...old, prosjekt: value }; });
                            }} />
                    </div>
                </div>

                <div className="row flexApart">
                    <p>{t("Time", "Tid")}:</p>
                    <Timer showSecondsIfNoHour={true} startDate={time?.startDate ? new Date(time?.startDate) : null} countUp={time?.startDate !== null} />
                </div>
            </div>

            <div className="column small-gap">


                <div className="row flexApart stretch-width">
                    <button className={"btn btn-primary" + (time?.pause ? " buttonSelected" : "")} onClick={
                        () => {
                            let timeCopy = { ...time }
                            timeCopy.pause = timeCopy.pause ? false : {
                                hours: 0,
                                minutes: 30,
                            }
                            setTime(timeCopy)
                        }
                    }>{t("Pause", "Pause")} <PauseIcon /></button>

                    {!time?.startDate ?
                        <ButtonOnlyHitOnce key="start-button" className="btn btn-primary good" onClick={
                            async () => {
                                const tempUpdate = {
                                    ...time,
                                    startDate: new Date(),
                                    stoppet: false,
                                }
                                setTime(tempUpdate)
                            }
                        }>{t("Start", "Start")} <PlayIcon /></ButtonOnlyHitOnce>
                        :
                        <button key="stop-button" className="btn btn-primary recording" onClick={
                            () => {
                                let timeCopy = { ...time }
                                timeCopy.endDate = new Date()
                                setTime(old => timeCopy)
                                //const overlap = checkForOverlap(timeCopy)
                            }
                        }>{t("Stop", "Stopp")} <SquareRounded /></button>
                    }
                </div>
            </div>
        </div >
    )
}
